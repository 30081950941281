// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import './src/styles/global.css'

const onRouteUpdate = ({ location, prevLocation }) => {
  window.curLocation = location
  window.prevLocation = prevLocation
}

export { onRouteUpdate }
